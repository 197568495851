import * as React from "react";

import { withTrans } from "../../i18n/withTrans";

import { pageTexts } from "../../content/textrends-award-license-terms-conditions";

import textrendsAwardPdf from "../../files/textrends-award-license-terms-conditions.pdf"; 

// components
import Layout from "../../components/layout";

class textrendsAwardLicenseTermsConditions extends React.Component {
  constructor(props) {
    super(props);
    this.t = props.t;
    this.content = pageTexts(this.t);
  }

  render() {
    return (
        <Layout pageName="textrends-award-license-terms-conditions" whiteMenu={true}>
          <div className="d-flex justify-content-center pb-20 pt-150">
            <embed src={textrendsAwardPdf} width="800px" height="1150px" />
          </div>
        </Layout>    
    );
  }
}

export default withTrans(textrendsAwardLicenseTermsConditions);
