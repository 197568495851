export const pageTexts = (t) => {
  const NUMBER = 17; // 17 title + text on the Privacy Policy Page
  let termsText = [];
  for (let i = 1; i <= NUMBER; i++) {
    termsText.push({
      text: t("terms-conditions." + i + "title"),
      colorClass: "colorDark",
      textType: "subtitle",
      extraClass: "font18 pt-20 fontWeightBold",
    });
    termsText.push({
      text: t("terms-conditions." + i + "text"),
      colorClass: "colorDark",
      extraClass: "font14 lineHeight1_7 termsStyle",
      textType: "paragraph",
      heading: "div",
    });
  }

  return {
    topTitle: {
      title: {
        text: t("terms-conditions.generalTerms"),
        colorClass: "colorDark",
        extraClass: "font26 text-center",
        heading: "h1",
      },
      text: {
        text: t("terms-conditions.version"),
        colorClass: "colorDark",
        extraClass: "font14 text-center",
      },
      separator: false,
    },
    terms: termsText,
  };
};
